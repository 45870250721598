import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export let routes = [
	// 主页部分
	{
		path: '/',
		component: () => import('@v/frame/Home'),
		meta: { renderer: true },
		children: [
			{
				path: '',
				name: 'index',
				component: () => import('@v/home-index/Index'),
				meta: { title: '主页' }
			},
			{
				path: 'business',
				component: () => import('@v/home-business/Index'),
				meta: { title: '主营业务' },
				children: [
					{
						path: '',
						name: 'business',
						component: () => import('@v/home-business/List'),
					},
					{
						path: 'detail',
						name: 'business-detail',
						component: () => import('@v/home-business/Detail'),
					},
					{
						path: 'sample',
						name: 'business-sample',
						component: () => import('@c/Content'),
					}
				]
			},
			{
				path: 'news',
				component: () => import('@v/home-news/Index'),
				meta: { title: '新闻动态' },
				children: [
					{
						path: '',
						name: 'news',
						component: () => import('@v/home-news/List'),
					},
					{
						path: 'detail',
						name: 'news-detail',
						component: () => import('@c/Content'),
					}
				]
			},
			{
				path: 'about',
				name: 'about',
				component: () => import('@v/home-about/Index'),
				meta: { title: '关于' }
			}
		]
	},
	// 预留的管理模块入口
	{
		path: '/admin',
		component: () => import('@v/frame/Admin')
	}
];

export default new Router({
	mode: 'history',
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition)
			return savedPosition;
		else if (to.hash)
			return { selector: to.hash };
		else
			return { x: 0, y: 0 };
	}
});
