import Vue from 'vue';
import jQuery from 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap/dist/css/bootstrap.min.css';
import router from '@js/router';
import store from '@js/store';
import '@css/common.less';

Vue.config.productionTip = false;
Vue.config.warnHandler = function (msg, vm, trace) {
	// eslint-disable-next-line
	console.error(`[Vue warn]: ${msg}${trace}\n\nView model:`, vm);
};

Vue.prototype.$jq = jQuery;

new Vue({
	el: '#app',
	router,
	store,
	render: h => h('router-view')
});